<template>
  <div class="content-container">
    <PageTitle :title="$t('SCHOOLS')" class="page-title">
      <template #extra-content>
        <BasicButton :button-text="$t('SCHOOLS_HELPDESK.CREATE_SCHOOL')" @click="createSchool()"/>
      </template>
    </PageTitle>

    <h1 class="page-section-title section-title">{{ $t('SCHOOLS_HELPDESK.OVERVIEW') }}</h1>

    <!-- Schools table -->
    <CustomTable
      ref="customTable"
      :options="schoolsTableOptions"
      :table-data="searchResults.schools"
      :pagination-data="searchResults"
      :search-function="manageSearch"
      :less-padding="true"
      :search-bar-options="
        {
          placeholder: $t('SCHOOLS_HELPDESK.SEARCH_PLACEHOLDER'),
          searchId: ''
        }"
      :no-results-data="noResultsData"
      table-class="schools-table school-overview-table">
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #brin="props">
        <div>{{ props.rowData.brin }}</div>
      </template>
      <template #postalCode="props">
        <div>{{ props.rowData.postalCode }}</div>
      </template>
      <template #city="props">
        <div>{{ props.rowData.city }}</div>
      </template>
      <template #province="props">
        <div>{{ props.rowData.province }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ showJustDate(props.rowData.createdAt) }}</div>
      </template>
      <template #updatedAt="props">
        <div>{{ showJustDate(props.rowData.updatedAt) }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('VIEW')"
                        :icon="require('../../../assets/icons/icn_visibility_white.svg')"
                        custom-class="button-blue"
                        @click="viewDetails(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p>{{ searchResults.totalItems + " " }} {{ $t('SCHOOLS_HELPDESK.TOTAL_SCHOOLS') }} </p>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import { computed, ref } from '@vue/reactivity'
import { SEARCH_SCHOOLS } from '@/store/modules/auth/actions'
import { showJustDate } from '@/utils/helpers/DateFormatter'
import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, SCHOOLS_TABLE } from '@/utils/helpers/customTable/CustomTableHelper'
import ActionButton from '@/components/table/ActionButton'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import { createNoResultsData } from '@/utils/helpers/customTable/EmptyTableHelper'
import BasicButton from '@/components/elements/basic/BasicButton'
export default {
  name: 'SchoolOverview',
  components: {
    ActionButton,
    CustomTable,
    PageTitle,
    BasicButton,
  },
  setup() {
    const router = useRouter()

    const store = useStore()
    const customTable = ref(null)

    function createSchool() {
      router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_CREATE })
    }

    /** Table Actions **/
    function viewDetails(school) {
      router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_DETAILS, params: { schoolId: school.id } })
    }

    const searchResults = computed(() => store.getters.getSearchedSchools)

    function searchSchool(payload) {
      return store.dispatch(SEARCH_SCHOOLS, payload)
    }

    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      searchSchool(payload)
    }

    const noResultsData = computed(() => {
      if (!customTable.value) {
        return {}
      }
      return createNoResultsData('SCHOOLS_HELPDESK', require('@/assets/img/icn_class@2x.png'), customTable.value.lastSearch)
    })

    const schoolsTableOptions = getTableOptions(SCHOOLS_TABLE)
    /** Search **/

    return {
      ROUTE_NAMES_HELPDESK,

      /** Table **/
      schoolsTableOptions,
      customTable,
      createSchool,

      /** Table Actions **/
      viewDetails,
      manageSearch,
      showJustDate,
      /** Schools **/
      searchResults,
      noResultsData,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.create-school {
  font-size: rem(16);
  width: rem(210);
  height: rem(41);
  padding: 0;
  line-height: rem(41);
}

:deep(.search-input) {
  width: rem(400);
}
</style>
